import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import { Container, Row, Col } from 'react-bootstrap';
import StaticImg from "../../../images/static-banner-1.jpg";
import StaticImg2 from "../../../images/static-banner-2.jpg";
import StaticImg3 from "../../../images/static-banner-3.jpg";
import ReactMarkdown from "react-markdown"
import GoogleReviews from "../../../images/google-reviews.png"
import NoImage from '../../../images/no-image.png';
import './OfficeBanner.scss';
import { Reviews_Page_Url } from "@Components/common/site/constants";
import { inViewOptions, contentItemStagger, contentItem } from '@Components/utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";
import GetURL from '@Components/common/site/get-url';
import axios from "axios"
import _ from "lodash"
import wordsToNumbers from 'words-to-numbers';
import ImageTransform from "@Components/common/ggfx-client/module/components/image-transform";
import {token} from "@Components/forms/api/Api"

const OfficeBanner = (props) => {
	const [isOpen, setIsOpen] = useState(false);
	const toggling = () => setIsOpen(!isOpen);
	const [testimonials, setTestimonials] = useState([])
	const [isClient, setClient] = useState(false);
	const getitems = async url => {
	  try {
		const { data } = await axios.get(url, {
			headers: {
				Authorization: `Bearer ${token}`,
				// "Content-Type": "multipart/form-data"
			}
		})// could be from env files
		setTestimonials(data?.filter(list => list.name.toLowerCase().includes(props.reviewId)))
	  } catch (error) {
		// cache it if fail/error;
		console.error(error)
	  }
	}
	useEffect(() => {
  
	  let url = `${process.env.GATSBY_STRAPI_SRC}/stb-google-reviews/google-review-reviews`
	  getitems(url);
	  setClient(true);
	}, [])
	var testimonial_count = testimonials ? testimonials.length : 0;
	var rating_count = 0;
	testimonials && testimonials.length > 0 && testimonials.map((item, ind) => {
	  rating_count += parseInt(wordsToNumbers(item.starRating));
	})
	var rating_avg = rating_count / testimonial_count;
	return (
		<InView {...inViewOptions}>
			{({ ref, inView }) => (
				<motion.div
					className="office-banner"
					ref={ref}
					initial="hidden"
					animate={inView ? "visible" : "hidden"}
					variants={contentItemStagger}
				>
					<picture>
						{/* <source media="(min-width: 992px)" srcSet={props.Banner_Image?.url ? props.Banner_Image?.url : NoImage} />
						<source media="(min-width: 768px)" srcSet={props.Banner_Image?.url ? props.Banner_Image?.url : NoImage} />
						<img src={props.Banner_Image?.url ? props.Banner_Image?.url : NoImage} alt={props.Banner_Image?.alternativeText} className="banner-img" /> */}
						 <ImageTransform imagesources={props?.Banner_Image?.url} renderer="srcSet" imagename='office.Banner_Image.detail'
                                attr={{ alt: props?.Banner_Image?.alternativeText+"- DNG Estate Agents", className: 'banner-img' }}
                                imagetransformresult={props.processedImages} id={props?.id} ></ImageTransform>
					</picture>
					<Container className="banner-wrap">
						<Row>
							<Col sm="12">
								<div className="banner-content">
									{props?.Banner_Title && <motion.h1 variants={contentItem}>{props?.Banner_Title}</motion.h1>}
									{props?.Banner_Content && <motion.div variants={contentItem}><ReactMarkdown source={props?.Banner_Content} allowDangerousHtml /></motion.div>}
									{/* {JSON.stringify(props.Cta_Links)} */}
									<motion.div className="d-none d-lg-flex" variants={contentItem}>
										{props.Cta_Links?.CTA_Btn_Label1 && props.Cta_Links?.HashLink1 &&
											<a onClick={props.executeScroll} className="btn">{props.Cta_Links?.CTA_Btn_Label1}</a>
										}
										{props.Cta_Links?.CTA_Btn_Label1 && props.Cta_Links?.CTA_Btn_Url1?.Alias &&
											<GetURL class="btn" label={props.Cta_Links?.CTA_Btn_Label1} alias={props.Cta_Links?.CTA_Btn_Url1?.Alias} />

										}
										{props.Cta_Links?.CTA_Btn_Label2 && props.Cta_Links?.CTA_Btn_Url2?.Alias &&
											<GetURL class="btn outline-btn" label={props.Cta_Links?.CTA_Btn_Label2} alias={props.Cta_Links?.CTA_Btn_Url2?.Alias} />
										}
									</motion.div>
									{props.reviewId && !_.isEmpty(testimonials) &&
									<motion.div className='google-review' variants={contentItem}>
											<div className="review-logo">
												<img src={GoogleReviews} alt="reviews" width="62" />
											</div>
											<p className="d-md-none">Rated {parseFloat(rating_avg).toFixed(1)} out of {testimonial_count}</p>
											<p className="d-none d-md-block">Rated {parseFloat(rating_avg).toFixed(1)} from {testimonial_count} customer reviews</p>
										</motion.div>
									}
								</div>
							</Col>
						</Row>
					</Container>
				</motion.div>
			)}
		</InView>
	)
}

export default OfficeBanner
