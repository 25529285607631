import * as React from "react"
import ScrollAnimation from "react-animate-on-scroll"
import GoogleSingleMap from "../../map/google/property-detail-map"
import "./Map.scss"
// styles

// markup
const MapOffice = ( props ) => {

  return (
    props.property_details_map_data &&
    <React.Fragment>
      <div className="">
        <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
          {/* {props.property_details_map_data.Latitude} - {props.property_details_map_data.Longitude} */}
          { props.property_details_map_data.Latitude && props.property_details_map_data.Longitude &&
            <GoogleSingleMap lat={parseFloat(props.property_details_map_data.Latitude)} lng={parseFloat(props.property_details_map_data.Longitude)} height={"650px"} zoom={16}/>
          }
        </ScrollAnimation>
      </div>
    </React.Fragment>
  )
}

export default MapOffice