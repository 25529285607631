import React, { useState } from "react"
import { Helmet } from "react-helmet"
import Layout from "@Components/Layout/layout"
import SEO from "@Components/SEO/seo"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
import { Container, Row, Col } from "react-bootstrap"
import MapComponent from "@Components/PropertyDetails/PropertyMap/MapOffice"
import ScrollAnimation from "react-animate-on-scroll"
import Breadcrumbs from "@Components/Static/Breadcrumbs/Breadcrumbs"
import { inViewOptions } from "../utils/animations"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
import SubscribeForm from "@Components/StaticPageTwo/SubscribeForm/SubscribeForm"
import RelatedNews from "@Components/StaticPageTwo/RelatedNews/RelatedNews"
import logoBlack from "../../images/logo.svg"
import OfficeBanner from "@Components/Static/OfficeBanner/OfficeBanner"
import ContactSidebarNews from "@Components/Static/ContactSidebar/ContactSidebarNews"
import OpinionList from "@Components/Static/OpinionList/OpinionList"
import ReactMarkdown from "react-markdown"
import ContactCard from "./../Static/ContactCard/ContactCard"
import OpeningCard from "./../Static/OpeningCard/OpeningCard"
import OfficeProperty from "./../Static/OfficeProperty/OfficeProperty"
import ContactSidebar from "./../Static/ContactSidebar/ContactSidebar"
import RelatedTeam from "../../Components/StaticPageTwo/RelatedTeam/RelatedTeam"
import FeatureProperties from "@Components/PropertyDetails/FeatureProperties/FeatureProperties"
import PopularSearch from "../StaticPageTwo/PopularSearches/PopularSearch"

import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import NotFoundPage from "../../pages/404"
const containerVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.4,
      ease: [0.33, 1, 0.68, 1],
      duration: 0.4,
    },
  },
}

const titleVariants = {
  hidden: {
    opacity: 0,
    y: 40,
  },
  visible: {
    opacity: 1,
    y: 0,
    delay: 0.4,
    transition: {
      ease: [0.33, 1, 0.68, 1],
      duration: 1,
    },
  },
}

const contentVariants = {
  hidden: {
    opacity: 0,
    y: 40,
  },
  visible: {
    opacity: 1,
    y: 0,
    delay: 1,
    transition: {
      ease: [0.33, 1, 0.68, 1],
      duration: 1,
    },
  },
}

const OfficeDetails = props => {
  const GET_Office_DETAILS = gql`
    query GetOffice($URL: String!) {
      offices(where: { URL: $URL }) {
        id
        imagetransforms
        About
        Address
        Branch_Location
        Licence_Number
        City
        Contact_No
        Toggle_Box {
          Content
          Tilte
        }
        Choose_Team {
          id
          imagetransforms
          Designation
          Name
          Phone
          Email
          Image {
            alternativeText
            url
          }
        }
        County
        Email
        Head_Office_Branch_Location
        IsHeadOffice
        Latitude
        Longitude
        Office_Name
        Google_Reviews_Office_Name
        Postcode
        Publish
        Sort
        URL
        crm_office_id
        my_home_id
        teams {
          id
          imagetransforms
          Name
          Phone
          Mobile_No
          URL
          Email
          Designation
          Image {
            alternativeText
            url
          }
          Video_Url
          offices {
            Branch_Location
          }
        }
        Add_Open_Hours {
          Day
          Time
        }
        Banner_Image {
          alternativeText
          url
        }
      }
    }
  `
  const { loading, error, data } = useQuery(GET_Office_DETAILS, {
    variables: { URL: props.slug },
  })
  let shareurl = ""

  if (typeof window !== "undefined") {
    shareurl = window.location.href ? window.location.href : ""
  }

  if (loading)
    return (
      <div className="static-news gql-loading-screen">
        <Container>
          <div className="gql-loading">
            <img
              className="logo-white loader-logo"
              src={logoBlack}
              alt="logo"
            />
          </div>
        </Container>
      </div>
    )

  var searchParams = { areas: props.slug }

  return data?.offices?.length > 0 ? (
    <InView {...inViewOptions}>
      {({ ref, inView }) => {
        return (
          <motion.section
            ref={ref}
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
            variants={containerVariants}
          >
            <React.Fragment>
              <Layout className="blog-page">
                {/* {JSON.stringify(data?.offices)} */}
                {data?.offices?.map((Page, i) => {
                  let processedImages = JSON.stringify({})
                  if (Page?.imagetransforms?.Banner_Image_Transforms) {
                    processedImages = Page.imagetransforms.Banner_Image_Transforms
                  }
                  //console.log("Page",Page)
                  //console.log("offc Contact_No", Page?.Contact_No)
                  return (
                    <>
                      <SEO
                        title={
                          Page?.Branch_Location
                            ? "Property Consultants in " + Page.Branch_Location
                            : ""
                        }
                        description={
                          Page?.Branch_Location
                            ? "Our property consultants in " +
                              Page.Branch_Location +
                              " offer the best property advice in Selling, Buying, Letting and Renting. Contact us to get assistance."
                            : ""
                        }
                        image={
                          Page?.Banner_Image?.url ? Page.Banner_Image.url : null
                        }
                      />
                   <Helmet>
                        <script type="application/ld+json">{`{
              
                    "@context": "https://schema.org",
                    "@type": "RealEstateAgent",
                    "name": "Property Consultants in ${Page?.Branch_Location} | DNG Estate Agents",
                    "image": "${Page?.Banner_Image?.url}",
                    "@id": "${shareurl}",
                    "url": "${shareurl}",
                    "telephone": "${Page?.Contact_No}",
                    "address": {
                      "@type": "PostalAddress",
                      "streetAddress": "${Page?.Address}",
                      "addressLocality": "${Page?.County}",
                      "postalCode": "${Page?.Postcode}",
                      "addressCountry": "IE"
                    },
                    "geo": {
                      "@type": "GeoCoordinates",
                      "latitude": ${Page?.Latitude},
                      "longitude": ${Page?.Longitude}
                    },
                    "openingHoursSpecification": {
                      "@type": "OpeningHoursSpecification",
                      "dayOfWeek": [
                        "Monday",
                        "Tuesday",
                        "Wednesday",
                        "Thursday",
                        "Friday"
                      ],
                      "opens": "09:00",
                      "closes": "17:30"
                    },
                    "sameAs": [
                      "https://www.facebook.com/douglasnewmangood/",
                      "https://twitter.com/dng_ie",
                      "https://www.instagram.com/dng.ie/",
                      "https://www.linkedin.com/company/dngestateagents/"
                    ] 
            }`}            
                  </script>
              </Helmet>
                      <Helmet
                        bodyAttributes={{
                          class: `templates-blog-details-template office-details-page`,
                        }} 
                      />
                      <Breadcrumbs
                        Page={Page?.Branch_Location}
                        class="static"
                        alias={"branch-finder"}
                        type="details-page"
                      />
                      <OfficeBanner
                        Banner_Image={Page?.Banner_Image}
                        Banner_Content={Page?.Address}
                        Banner_Title={Page?.Office_Name}
                        Banner_Type={Page?.Banner_Type}
                        reviewId={Page?.Google_Reviews_Office_Name}
                        processedImages={processedImages}
                        id={Page?.id}
                      />
                      <div className="static-block">
                        <Container>
                          <Row>
                            <Col xl="7" className="col-xxl-8 col-custom-8">
                              <Row>
                                <Col md="5">
                                  <ContactCard
                                    Address={Page?.Address}
                                    Contact_No={Page?.Contact_No}
                                    Email={Page?.Email}
                                    Branch_Location={Page?.Branch_Location}
                                    County={Page?.County}
                                    branchLink={Page?.URL}
                                  />
                                </Col>
                                <Col md="6">
                                  <OpeningCard
                                    Add_Open_Hours={Page?.Add_Open_Hours}
                                    Licence_Number={Page?.Licence_Number}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <OfficeProperty
                                    slug={props.slug}
                                    Toggle_Box={Page?.Toggle_Box}
                                    About={Page?.About}
                                    Branch_Location={Page?.Branch_Location}
                                    Office_id={Page?.crm_office_id}
                                    HomeId={Page?.my_home_id}
                                  />
                                </Col>
                              </Row>
                            </Col>
                            <Col xl="5" className="col-xxl-4 col-custom-4">
                            <div className="sidebar-bk">
                              <ContactSidebar
                                Office_Name={Page?.Office_Name}
                                Email={Page?.Email}
                                officedetailspage={true}
                                Choose_Team={Page.Choose_Team}
                                Contact_No={Page?.Contact_No}
                                pageUrl={shareurl}
                                off_address={Page?.Address}
                              />
                              </div>
                            </Col>
                          </Row>
                        </Container>
                      </div>
                      {Page.teams?.length > 0 && (
                        <RelatedTeam
                          slug={props.slug}
                          teams={Page.teams}
                          Branch_Location={Page.Branch_Location}
                        />
                      )}
                    </>
                  )
                })}

                <div
                  className="property-details-landing-map"
                  id="property-details-map"
                  data-item="property-details-map"
                >
                  <MapComponent property_details_map_data={data?.offices[0]} />
                </div>

                <FeatureProperties
                  slug={props.slug}
                  Branch_Location={data?.offices[0]?.Branch_Location}
                  office_data={data?.offices[0]}
                />

                <section className="popular-search-result">
                  <Container>
                    <Row>
                      <Col>
                        <PopularSearch
                          searchParams={searchParams}
                          SearchType={"Office_Details"}
                        />
                      </Col>
                    </Row>
                  </Container>
                </section>
              </Layout>
            </React.Fragment>
          </motion.section>
        )
      }}
    </InView>
  ) : (
    <Layout className="blog-page">
      <Router>
        <Switch>
          <Route component={NotFoundPage} />
        </Switch>
      </Router>
    </Layout>
  )
}

export default OfficeDetails
